import React, { Fragment } from 'react'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'
import { getApi } from '../../api'
import { BILLING_INFO } from '../new-purchase'

const withState = provideState({
  initialState: () => ({
    quoteNumber: '',
    modal: false
  }),
  effects: {
    async createOrderFromQuote(effects, e) {
      e.preventDefault()
      e.stopPropagation()
      try {
        const order = await getApi(this.state.role).createOrderFromQuote(
          this.state.xwToken,
          this.state.quoteNumber
        )
        await effects.continueOrder(order, BILLING_INFO)
        effects.toggleModal()
      } catch (error) {
        effects.handleError(error)
      }
    },
    onChangeQuoteNumber(_, event) {
      this.state.quoteNumber = event.target.value.replace(/[^0-9]/g, '')
    },
    toggleModal() {
      this.state.modal = !this.state.modal
    }
  }
})

const ExistingQuote = ({ state, effects }) => (
  <Fragment>
    <Button outline onClick={effects.toggleModal}>
      Existing quote
    </Button>
    <Modal isOpen={state.modal} toggle={effects.toggleModal}>
      <Form onSubmit={effects.createOrderFromQuote}>
        <ModalHeader toggle={effects.toggleModal}>
          You already have a quote ?
        </ModalHeader>
        <ModalBody>
          <div style={{ marginBottom: '1.5rem' }}>
            If you have a quote, enter its number here and follow your order.
          </div>
          <FormGroup>
            <Label for="quoteNumber" style={{ fontWeight: 'bold' }}>
              Quote number
            </Label>
            <Input
              required
              type="text"
              id="quoteNumber"
              placeholder="1234"
              value={state.quoteNumber}
              onChange={effects.onChangeQuoteNumber}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={effects.toggleModal}>
            Cancel
          </Button>
          <Button color="primary">Generate order</Button>
        </ModalFooter>
      </Form>
    </Modal>
  </Fragment>
)

export default withState(injectState(ExistingQuote))
